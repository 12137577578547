import {
  APPLICATIONS,
  ApiV1,
  COURSES,
  COURSE_LEVEL,
  DOCUMENTS,
  STUDENTS,
  COURSES_DETAIL,
  SPONSOR_STUDENTS,
  NEWS,
  COMMENTS,
  USERS,
  LEADS,
  APPLIED_STUDENT,
  UNIVERSITY_DETAIL,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "../API";
import Resizer from "react-image-file-resizer";
import { PDFDocument } from "pdf-lib";

export const getStudents = async (data: any) => {
  try {
    const res = await ApiV1.get(`${STUDENTS}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
        qualified: data.qualified,
        degree: data.degree,
        nationality: data.nationality,
        residence: data.residence,
        status: data.status,
        stage: data.stage,
        ambassadorName: data.ambassadorName,
        assignedUserId: data.assignedUserId,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getLeaderBoard = async (
  feeType: any,
  startDate: any,
  endDate: any
) => {
  try {
    const res = await ApiV1.get(
      `/fees/sales?feeType=${feeType}&startDate=${startDate}&endDate=${endDate}`
    );

    return res.data;
  } catch (error) {
    return error;
  }
};
export const getTop5ByCountry = async (data: any) => {
  try {
    const res = await ApiV1.get(`${LEADS}/country`, {
      params: {
        qualified: data.qualified,
        degree: data.degree,
        status: data.status,
        stage: data.stage,
        startDate: data.startDate,
        endDate: data.endDate,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTop5BAppliedNationality = async (data: any) => {
  try {
    const res = await ApiV1.get(`${STUDENTS}/top-nationalities`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
      },
    });

    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTop5ByDegree = async (data: any) => {
  try {
    const res = await ApiV1.get(`${LEADS}/degree`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getPhaseCount = async (data: any) => {
  try {
    const res = await ApiV1.get(`${APPLICATIONS}/application-counts`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getStudentsByStatus = async (data: any) => {
  try {
    const res = await ApiV1.get(`${STUDENTS}/count`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTopUniversities = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${APPLICATIONS}/application-counts-university`,
      {
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTopUniversitiesEnrolled = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${APPLICATIONS}/application-enrolled-university`,
      {
        params: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTopSchools = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `/fees/top-schools?startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTopTests = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `/fees/top-tests?startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTopTypes = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `/fees/top-types?startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTopCities = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `/fees/top-cities?startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const searchLeads = async (text: any, data: any) => {
  try {
    const res = await ApiV1.get(`${LEADS}/search/${text.text}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        qualified: data.qualified,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateFees = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`/fees/${id}`, {
      ...data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateComments = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`/comments/${id}`, {
      ...data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateLeads = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`${LEADS}/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateQuotes = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`/quotes/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getLeads = async (data: any) => {
  try {
    const res = await ApiV1.get(`${LEADS}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
        qualified: data.qualified,
        degree: data.degree,
        nationality: data.nationality,
        residence: data.residence,
        status: data.status,
        webUrl: data.webUrl,
        source: data.source,
        startDate: data.startDate,
        endDate: data.endDate,
        "destination[en_name]": data.destination,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getExibitionStudents = async (data: any) => {
  try {
    const res = await ApiV1.get(`/exibitionStudent`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        status: data.status,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getFees = async (data: any) => {
  try {
    const res = await ApiV1.get(`/fees`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        feeType: data.feeType,
        user: data.user,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateExibitionStudent = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`/exibitionStudent/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateDocument = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`${DOCUMENTS}/${id}`, {
      ...data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getStudentById = async (id: any) => {
  try {
    const res = await ApiV1.get(`${STUDENTS}/${id}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getUserById = async (id: any) => {
  try {
    const res = await ApiV1.get(`${USERS}/${id}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getStudentsByDates = async (data: any) => {
  try {
    const res = await ApiV1.get(`${STUDENTS}/months`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
        qualified: data.qualified,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getFeesPerMonth = async () => {
  try {
    const res = await ApiV1.get(`/fees/months`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getDashboardDataLeads = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${LEADS}/leads-dashboard-data?startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getDashboardDataStudents = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${STUDENTS}/student-dashboard-data?startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getDashboardData = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `/fees/fees-dashboard-data?startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getApplicationPerMonth = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${APPLICATIONS}/count-by-month?intakeMonth=${data.intakeMonth}&intakeYear=${data.intakeYear}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getApplicationData = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${APPLICATIONS}/application-dashboard-data?intakeMonth=${data.intakeMonth}&intakeYear=${data.intakeYear}&startDate=${data.startDate}&endDate=${data.endDate}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getEnrolledApplicationPerMonth = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${APPLICATIONS}/enrolled-count-by-month?intakeMonth=${data.intakeMonth}&intakeYear=${data.intakeYear}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAppliedStudenntPerMonth = async () => {
  try {
    const res = await ApiV1.get(`${APPLIED_STUDENT}/months`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getRoles = async () => {
  try {
    const res = await ApiV1.get(`/roles`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getLeadsByDates = async (data: any) => {
  try {
    const res = await ApiV1.get(`${LEADS}/months`, {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
        qualified: data.qualified,
        status: data.status,
        source: data.source,
        "destination[en_name]": data.destination,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};
export function truncateString(inputString: string, maxLength = 30) {
  if (inputString?.length > maxLength) {
    return inputString.substring(0, maxLength - 3) + "...";
  } else {
    return inputString;
  }
}

export const updateStudent = async (studentId: any, data: any) => {
  try {
    const res = await ApiV1.patch(`${STUDENTS}/${studentId}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`${USERS}/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateApplication = async (id: any, data: any) => {
  try {
    const res = await ApiV1.patch(`${APPLICATIONS}/${id}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStudent = async (id: any) => {
  try {
    const res = await ApiV1.delete(`${STUDENTS}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteComments = async (commentId: any) => {
  try {
    const res = await ApiV1.delete(`${COMMENTS}/${commentId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteQuotes = async (id: any) => {
  try {
    const res = await ApiV1.delete(`/quotes/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFee = async (id: any) => {
  try {
    const res = await ApiV1.delete(`fees/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id: any) => {
  try {
    const res = await ApiV1.delete(`users/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStudentApplication = async (id: any) => {
  try {
    const res = await ApiV1.delete(`${APPLICATIONS}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const deleteStudentDocument = async (id: any) => {
  try {
    const res = await ApiV1.delete(`${DOCUMENTS}/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchStudents = async (text: any, data: any) => {
  try {
    const res = await ApiV1.get(`${STUDENTS}/search/${text.text}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        stage: data.stage,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchFees = async (text: any, data: any) => {
  try {
    const res = await ApiV1.get(`/fees/search/${text.text}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        stage: data.stage,
        populate: data.search,
        feeType: data.feeType,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCourseLevel = async (data: any) => {
  try {
    const res = await ApiV1.get(`${COURSE_LEVEL}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getSubjects = async (data: any) => {
  try {
    const res = await ApiV1.get(`/subjects`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getSchools = async (data: any) => {
  try {
    const res = await ApiV1.get(`/school`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getQuotes = async (data: any) => {
  try {
    let param;
    if (data.visibility) {
      param = {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
        visibility: data.visibility,
      };
    } else {
      param = {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
      };
    }
    const res = await ApiV1.get(`/quotes`, {
      params: param,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const searchCountries = async (data: any) => {
  try {
    const res = await ApiV1.get(`/countries/search/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchCSchools = async (data: any) => {
  try {
    const res = await ApiV1.get(`/school/search/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchEngLangSchools = async (data: any) => {
  try {
    const res = await ApiV1.get(`/englishLangSchool/search/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchSubjects = async (data: any) => {
  try {
    const res = await ApiV1.get(`/subjects/search/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const searchCourses = async (data: any) => {
  try {
    const res = await ApiV1.get(`/${COURSES}/search/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const searchUniversities = async (data: any) => {
  try {
    const res = await ApiV1.get(`/universities/search/${data.text}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createDocument = async (data: any) => {
  try {
    const res = await ApiV1.post(`/documents`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createQuotes = async (data: any) => {
  try {
    const res = await ApiV1.post(`/quotes`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createAppliedStudent = async (data: any) => {
  try {
    const res = await ApiV1.post(`${APPLIED_STUDENT}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (data: any) => {
  try {
    const res = await ApiV1.post(`/users`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addFeesData = async (data: any) => {
  try {
    const res = await ApiV1.post(`/fees`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createSchool = async (data: any) => {
  try {
    const res = await ApiV1.post(`/school`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createEngSchool = async (data: any) => {
  try {
    const res = await ApiV1.post(`/englishLangSchool`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const uploadNumbers = async (data: any) => {
  try {
    const res = await ApiV1.post(`/exibitionStudent`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export function getLast7DaysDates() {
  const today = new Date();
  const end = new Date(today); // end date is today

  const start = new Date(today);
  start.setDate(today.getDate() - 6); // start date is 7 days ago

  return { start, end };
}
export function getTodayAndNextDate() {
  const today = new Date();
  const nextDate = new Date(today);
  nextDate.setDate(today.getDate() + 1);

  return {
    today: today.toLocaleDateString("en-US"),
    nextDate: nextDate.toLocaleDateString("en-US"),
  };
}
export function getYesterdayAndNextDate() {
  const today = new Date();
  const nextDate = new Date(today);
  nextDate.setDate(today.getDate() - 1);

  return {
    today: nextDate.toLocaleDateString("en-US"),
    nextDate: today.toLocaleDateString("en-US"),
  };
}
export function getThisWeekDates() {
  const today = new Date();
  const currentDay = today.getDay();
  const startOfWeek = new Date(today);
  const endOfWeek = new Date(today);

  // Calculate the start date of the week (Sunday)
  startOfWeek.setDate(today.getDate() - currentDay);

  // Calculate the end date of the week (Saturday)
  endOfWeek.setDate(today.getDate() + (6 - currentDay));

  return { startOfWeek, endOfWeek };
}

export function getLastWeekDates() {
  const today = new Date();
  const currentDay = today.getDay();
  const startOfLastWeek = new Date(today);
  const endOfLastWeek = new Date(today);

  // Calculate the start date of last week (Sunday)
  startOfLastWeek.setDate(today.getDate() - currentDay - 7);

  // Calculate the end date of last week (Saturday)
  endOfLastWeek.setDate(today.getDate() - currentDay - 1);

  return { startOfLastWeek, endOfLastWeek };
}

export function getThisMonthDates() {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return { startOfMonth, endOfMonth };
}

export function getLastMonthDates() {
  const today = new Date();
  const startOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  return { startOfLastMonth, endOfLastMonth };
}

export const createComment = async (data: any) => {
  try {
    const res = await ApiV1.post(`${COMMENTS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const createCommentTag = async (id: any, data: any) => {
  try {
    const res = await ApiV1.post(`${COMMENTS}/${id}/tag`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createNews = async (data: any) => {
  try {
    const res = await ApiV1.post(`${NEWS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createApplication = async (data: any) => {
  try {
    const res = await ApiV1.post(`${APPLICATIONS}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getStudentDocById = async (studentId: any) => {
  try {
    const res = await ApiV1.get(`${DOCUMENTS}/student/${studentId}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getApplicationsById = async (studentId: any) => {
  try {
    const res = await ApiV1.get(`${APPLICATIONS}/student/${studentId}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getApplicationById = async (id: any) => {
  try {
    const res = await ApiV1.get(`${APPLICATIONS}/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getCommentsByStudentid = async (id: any) => {
  try {
    const res = await ApiV1.get(`${COMMENTS}/student/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};

export const getCourseById = async (id: any) => {
  try {
    const res = await ApiV1.get(`${COURSES}/${id}`);
    return res;
  } catch (error) {
    return error;
  }
};
export const getCourseBycourseRefId = async (courseRefId: any) => {
  try {
    const res = await ApiV1.get(`${COURSES_DETAIL}/${courseRefId}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCourseDetailByUni = async (institute: any) => {
  try {
    const res = await ApiV1.get(`${COURSES_DETAIL}/institute/${institute}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCourses = async (data: any) => {
  try {
    const res = await ApiV1.get(
      `${COURSES}/?subjects=${data.subject}&country=United%20Kingdom&attendanceTypes=${data.attendanceTypes}&courseLevel=${data.courseLevel}&institution=${data.institution}&intakeMonths=${data.intakeMonths}&courseDurationValues=${data.courseDurationValues}&placementAvailable=${data.placementAvailable}&city=${data.city}`,
      {
        params: {
          limit: data.limit,
          page: data.page,
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getApplications = async (data: any) => {
  try {
    const res = await ApiV1.get(`${APPLICATIONS}`, {
      params: {
        status: data.status,
        intakeYear: data.intakeYear,
        intakeMonth: data.intakeMonth,
        confrimStatus: data.confrimStatus,
        courseLevel: data.courseLevel,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getUsers = async () => {
  try {
    const res = await ApiV1.get(`${USERS}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getUsersWithoutPagination = async () => {
  try {
    const res = await ApiV1.get(`${USERS}/users`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAmbassadors = async () => {
  try {
    const res = await ApiV1.get(`/ambassadors`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const createStudent = async (data: object) => {
  try {
    const res = await ApiV1.post(`${STUDENTS}`, {
      ...data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createCourse = async (data: object) => {
  try {
    const res = await ApiV1.post(`${COURSES}`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const res = await ApiV1.post(`${FORGOT_PASSWORD}`, {
      email,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data: any) => {
  try {
    const res = await ApiV1.post(`${RESET_PASSWORD}`, {
      password: data.password,
      token: data.token,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createCourseDetails = async (data: object) => {
  try {
    const res = await ApiV1.post(`/courseDetails`, {
      ...data,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getSponsorStudents = async (data: any) => {
  try {
    const res = await ApiV1.get(`${SPONSOR_STUDENTS}`, {
      params: {
        limit: data.limit,
        page: data.page.toString(),
        sortBy: data.sortBy,
        populate: data.search,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getNews = async () => {
  try {
    const res = await ApiV1.get(`${NEWS}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const optimizeImage = async (selectedFile: Blob) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const reader = new FileReader();

    reader.onload = (event: any) => {
      image.src = event.target.result;

      image.onload = () => {
        const aspectRatio = image.width / image.height;
        const targetWidth = Math.min(image.width, 800); // Use higher width for better quality
        const targetHeight = targetWidth / aspectRatio;

        Resizer.imageFileResizer(
          selectedFile,
          targetWidth, // width
          targetHeight, // height
          "JPEG", // format
          100, // slightly reduce quality to speed up loading while maintaining decent quality
          0, // rotation
          (uri) => {
            resolve(uri);
          },
          "base64" // outputType
        );
      };
    };

    reader.onerror = reject;
    reader.readAsDataURL(selectedFile);
  });
};

export const dataURLtoFile = async (dataURL: any, filename: string) => {
  var arr = dataURL.split(","),
    mime: any = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const optimizePDF = async (selectedFile: File, filename: any) => {
  try {
    // Load the PDF from a file
    const arrayBuffer = await selectedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer, {
      ignoreEncryption: true,
    });

    // You can add optimization logic here if needed

    // Save the optimized PDF as a Uint8Array
    const optimizedPDFBytes = await pdfDoc.save();

    // Convert the optimized PDF bytes to a Blob
    const optimizedPDFBlob = new Blob([optimizedPDFBytes], {
      type: "application/pdf",
    });

    // Create a File object with the specified filename
    const optimizedPDFFile = new File([optimizedPDFBlob], filename, {
      type: "application/pdf",
    });

    // Return the File object
    return optimizedPDFFile;
  } catch (error) {
    console.error("Error optimizing PDF:", error);
    throw error; // Proper error handling
  }
};
export const getUniversityByuniRefId = async (universityRefId: any) => {
  try {
    const res = await ApiV1.get(`${UNIVERSITY_DETAIL}/${universityRefId}`);
    return res.data;
  } catch (error) {
    return error;
  }
};
