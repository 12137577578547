import * as React from "react";
import { CloseIcon } from "../../Assets";
import { InputComponent, Text } from "./styled";
import "./style.scss";
import {
  createEngSchool,
  createSchool,
  searchCSchools,
  searchEngLangSchools,
} from "../../Services/Function";
import { Button } from "react-bootstrap";

interface ICountryInputComponentProps {
  selectedCountry: any;
  setsearchText: any;
  setselectedCountry: any;
  arabic: boolean;
  searchCountry: any;
  countries: any;
  setcountries: any;
  country?: boolean;
  searchText: string;
  placeholder: string;
  onClickData?: any;
  removeContry?: any;
  dashbosardCon?: boolean;
  setaddSchool?: any;
  addSchool?: any;
  schoolDd?: boolean;
  university?: boolean;
  student?: any;
  onStudentClick?: any;
  setselectedSubject?: any;
  filterCourses?: any;
  onClickFn?: any;
  addCourse?: boolean;
  engSchoolDd?: boolean;
  autoComplete?: string;
  setselectedCourse?: any;
}

export const CountryInputComponent: React.FunctionComponent<
  ICountryInputComponentProps
> = (props) => {
  const {
    selectedCountry,
    setsearchText,
    setselectedCountry,
    arabic,
    searchCountry,
    countries,
    searchText,
    setcountries,
    placeholder,
    onClickData,
    removeContry,
    dashbosardCon,
    schoolDd,
    university,
    student,
    onStudentClick,
    setselectedSubject,
    filterCourses,
    onClickFn,
    addCourse,
    engSchoolDd,
    setselectedCourse,
  } = props;

  return (
    <div className="d-flex w-100 justify-content-center">
      <div className="d-flex w-100 flex-row" style={{ position: "relative" }}>
        {Object.keys(selectedCountry).length ? (
          <div
            onClick={async () => {
              setselectedCountry("");
              setsearchText("");
              if (setcountries) {
                setcountries([]);
              }
              if (setselectedSubject) {
                setselectedSubject([]);
              }
              await removeContry;
              if (filterCourses) {
                await filterCourses();
              }
            }}
            style={{
              right: !arabic ? 10 : "auto",
              left: !arabic ? "auto" : 10,
            }}
            className="input-cross"
          >
            <CloseIcon />
          </div>
        ) : null}
        {Object.keys(selectedCountry).length ? (
          <InputComponent
            onFocus={(e) => (e.target.value = "")}
            name="contry"
            type="text"
            className="form-input"
            autoComplete={props.autoComplete}
            style={{ textAlign: arabic ? "right" : "left", marginBottom: 10 }}
            value={
              university
                ? selectedCountry.name
                : arabic
                  ? selectedCountry.arabic_name
                  : student
                    ? `${selectedCountry.firstName || ""} ${
                        selectedCountry.middleName || ""
                      } ${selectedCountry.lastName || ""}`
                    : engSchoolDd
                      ? selectedCountry.name
                      : selectedCountry.english_name
            }
            arabic={arabic}
          />
        ) : (
          <>
            <InputComponent
              placeholder={placeholder}
              name="name"
              onFocus={(e) => (e.target.value = "")}
              onChange={(e) => {
                const trimmedText = e.target.value.trim();
                setsearchText(e.target.value);
                searchCountry({ target: { value: trimmedText } });
              }}
              type="text"
              className="search-input form-input"
              style={{ marginBottom: 10 }}
              autoComplete={props.autoComplete}
              value={searchText}
              arabic={arabic}
            />
            {schoolDd ? (
              <Button
                type="button"
                disabled={!searchText}
                style={{
                  height: 42,
                  cursor: "pointer",
                  marginLeft: 2,
                  backgroundColor: "#7f56D9",
                  borderColor: "#7f56D9",
                }}
                className="d-flex justify-content-between align-items-center"
                onClick={async () => {
                  const enteredSchool = searchText;
                  const found = countries?.some(
                    (school: any) => school.english_name === enteredSchool
                  );

                  if (!found) {
                    const newSchoolName = enteredSchool;
                    const newCountry = prompt(
                      "Enter country for the new school:"
                    );

                    if (newCountry) {
                      const newSchool = {
                        english_name: newSchoolName,
                        countryName: newCountry,
                      };
                      // Call the function to add the new school to the database
                      await createSchool(newSchool); // Replace 'addNewSchool' with your function
                      // Fetch schools again after adding the new one
                      const updatedRes = await searchCSchools({
                        text: searchText,
                      });
                      props.setcountries(updatedRes?.results);
                    } else {
                      // Handle if the user cancels entering the country
                      // Could be an alert, message, or other logic
                    }
                  }
                }}
              >
                Add
              </Button>
            ) : null}

            {engSchoolDd ? (
              <Button
                type="button"
                disabled={!searchText}
                style={{
                  height: 42,
                  cursor: "pointer",
                  marginLeft: 2,
                  backgroundColor: "#7f56D9",
                  borderColor: "#7f56D9",
                }}
                className="d-flex justify-content-between align-items-center"
                onClick={async () => {
                  const enteredSchool = searchText;
                  const found = countries?.some(
                    (school: any) => school.english_name === enteredSchool
                  );

                  if (!found) {
                    const newSchoolName = enteredSchool;
                    const newUrl = prompt("Enter logo url for the new school:");

                    if (newUrl) {
                      const newSchool = {
                        name: newSchoolName,
                        logo_url: newUrl,
                      };
                      // Call the function to add the new school to the database
                      await createEngSchool(newSchool); // Replace 'addNewSchool' with your function
                      // Fetch schools again after adding the new one
                      const updatedRes = await searchEngLangSchools({
                        text: searchText,
                      });
                      props.setcountries(updatedRes?.results);
                    } else {
                      // Handle if the user cancels entering the country
                      // Could be an alert, message, or other logic
                    }
                  }
                }}
              >
                Add
              </Button>
            ) : null}
          </>
        )}
        {countries.length ? (
          <div
            className="d-flex flex-column "
            style={{
              background: "#fff",
              position: "absolute",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              borderRadius: 8,
              maxHeight: 200,
              overflow: "scroll",
              top: 50,
              width: "100%",
              padding: "4px 0 4px 0",
              zIndex: 99,
            }}
          >
            {countries.map((a: any) => (
              <Text
                key={a.id}
                onClick={async () => {
                  setsearchText("");
                  await setcountries([]);
                  if (dashbosardCon) {
                    await onClickData();
                  }
                  if (student) {
                    await onStudentClick(a);
                  }
                  if (addCourse) {
                    setselectedCountry(a);
                    await onClickFn(a);
                  }
                  setselectedCountry(a);
                }}
                className="custom-dd-menu"
                style={{
                  padding: "5px 15px",
                }}
                arabic={arabic}
              >
                {university
                  ? a.name
                  : arabic
                    ? a.arabic_name
                    : student
                      ? `${a.firstName || ""} ${a.middleName || ""} ${
                          a.lastName || ""
                        }`
                      : engSchoolDd
                        ? a.name
                        : a.english_name}
              </Text>
            ))}{" "}
          </div>
        ) : searchText && !countries.length ? (
          <div
            className="d-flex flex-column"
            style={{
              background: "#fff",
              position: "absolute",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              borderRadius: 8,
              top: 50,
              width: "100%",
              zIndex: 99,
              padding: "10px 0 10px 0",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{ color: "#969696" }}
            >
              {"Loading..."}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
