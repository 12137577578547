/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "./ButtonComponent";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { FormInput } from "./FormInput";
import Select from "react-select";
import { Label } from "./FormInput/styled";
import { DropdownComponent } from "./DropdownComponent";
import {
  getUsers,
  getUsersWithoutPagination,
  searchEngLangSchools,
  updateFees,
} from "./../Services/Function";
import ReactDatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import { ToggleButton } from "./ToggleButton";
import { useSelector } from "react-redux";
import { CountryInputComponent } from "./CountryInputComponent";
import { debounce } from "debounce";

interface IAddFeesModalProps {
  show: boolean;
  onHide: any;
  reloadData?: any;
  feeType?: any;
  selectedFees: any;
}

export const EditFeeModal: React.FunctionComponent<IAddFeesModalProps> = (
  props
) => {
  const auth = useSelector((state: any) => state.auth);
  const [loading, setloading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(
    props.selectedFees?.tag.type
  );
  const [vfsUploaded, setvfsUploaded] = useState(
    props.selectedFees?.tag.vfsUploaded || false
  );
  const [docSent, setdocSent] = useState(
    props.selectedFees?.tag.docSent || false
  );
  const [moneyTransferred, setmoneyTransferred] = useState(
    props.selectedFees?.tag.moneyTransferdToSchool || false
  );
  const [users, setusers] = useState([]);
  const [selectedUser, setselectedUser] = useState<any>("");
  const [checkedBy, setcheckedBy] = useState<any>({});
  const [dateSubmitted, setdateSubmitted] = React.useState<any>(
    new Date(props.selectedFees?.tag.dateSubmitted) ||
      new Date(props.selectedFees?.createdDate) ||
      null
  );
  const [arrivalDate, setarrivalDate] = useState(
    new Date(props.selectedFees?.tag.arrivalDate) || null
  );
  const [phoneNo, setphoneNo] = React.useState(props.selectedFees?.tag.phoneNo);
  const [dateOfAppointment, setdateOfAppointment] = useState(
    new Date(props.selectedFees?.tag.dateOfAppointment) || null
  );
  const [dateOfTest, setdateOfTest] = useState(
    new Date(props.selectedFees?.tag.dateOfTest) || null
  );
  const [salesPerson, setsalesPerson] = useState<any>("");
  const [operations, setoperations] = useState<any>("");
  const [accountManager, setaccountManager] = useState<any>("");
  const [selectedSchool, setselectedSchool] = useState<any>(
    props.selectedFees?.tag.school || ""
  );
  const [addSchool, setaddSchool] = useState(false);
  const [schools, setschools] = useState([]);
  const [schoolSearch, setschoolSearch] = useState("");

  useEffect(() => {
    async function fetchData() {
      setloading(true);
      const res = await getUsersWithoutPagination();
      setusers(res);
      setselectedUser(
        res.results.filter(
          (a: any) =>
            a.id === props.selectedFees?.tag.salesPerson ||
            a.id === props.selectedFees?.tag.incharge
        )[0] || {}
      );
      setcheckedBy(
        res.results.filter(
          (a: any) => a.id === props.selectedFees?.tag.checkedBy
        )[0] || {}
      );
      if (props.selectedFees?.tag?.salesPerson) {
        setsalesPerson(
          res.results.filter(
            (a: any) => a.id === props.selectedFees?.tag?.salesPerson.id
          )[0] || {}
        );
      }
      if (props.selectedFees?.tag?.operation) {
        setoperations(
          res.results.filter(
            (a: any) => a.id === props.selectedFees?.tag?.operation.id
          )[0] || {}
        );
      }

      if (props.selectedFees?.tag?.accountManager) {
        setaccountManager(
          res.results.filter(
            (a: any) => a.id === props.selectedFees?.tag?.accountManager.id
          )[0] || {}
        );
      }
      setloading(false);
    }
    fetchData();
  }, []);

  const searchSchool = debounce(async (e: any) => {
    if (!e.target.value) {
      setschools([]);
    } else {
      const res = await searchEngLangSchools({ text: e.target.value });
      setschools(res?.results);
    }
  }, 500);

  const options = [
    { value: "EnglishLanguage", label: "English Language" },
    { value: "Bachelor", label: "Bachelors" },
    { value: "Masters", label: "Masters" },
    { value: "Foundation", label: "Foundation" },
    { value: "Translation", label: "Translation" },
    { value: "PersonalStatement", label: "Personal Statement" },
    { value: "CV", label: "CV" },
    { value: "PHD", label: "PHD" },
    // { value: "UCAS", label: "UCAS" },
  ];

  const handleChange = (selectedItems: any) => {
    setSelectedOption(selectedItems);
  };
  const excludedCountries = ["IL"];
  const filteredCountries = getCountries().filter(
    (country) => !excludedCountries.includes(country)
  );

  const formatDateInKuwait = (date: Date) => {
    if (!date) return null;

    // Kuwait is UTC+3, so we need to adjust the time zone
    const kuwaitTimeOffset = 3 * 60; // Kuwait is UTC+3 in minutes

    // Get the UTC date without affecting the time
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );

    // Add Kuwait time offset directly
    const kuwaitDate = new Date(utcDate.getTime() + kuwaitTimeOffset * 60000);

    // Return date in YYYY-MM-DD format (ignore time component)
    return kuwaitDate.toISOString().split("T")[0];
  };
  return (
    <div>
      <Modal
        style={{
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          borderRadius: 8,
        }}
        // id="myModal"
        show={props.show}
        onHide={() => props.onHide()}
        centered
      >
        <Formik
          initialValues={{
            amount: props.selectedFees.tag.amount,
            desc: props.selectedFees.tag.description,
            fullName: props.selectedFees.tag.fullName,
            type: [],
            vfsRefNo: props.selectedFees.tag.vfsRefNo,
            vfsEmail: props.selectedFees.tag.vfsEmail,
            ofcFee: props.selectedFees.tag.ofcFee,
            formOfPayment: props.selectedFees.tag.formOfPayment,
            passportNo: props.selectedFees.tag.passportNo,
            email: props.selectedFees.tag.email,
            password: props.selectedFees.tag.password,
            typeOfTest: props.selectedFees.tag.typeOfTest,
            formOfTest: props.selectedFees.tag.formOfTest,
            refNo: props.selectedFees.tag.refNo,
            paymentRecieved: props.selectedFees.tag.paymentRecieved,
            noOfWeeks: props.selectedFees.tag.noOfWeeks || "",
            location: props.selectedFees.tag.location,
            amountPaidKd: props.selectedFees.tag.amount || "",
          }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            setloading(true);
            let tag;

            if (props.feeType === "office-fees") {
              tag = {
                amount: parseInt(values.amount),
                description: values.desc,
                type: selectedOption,
                salesPerson: selectedUser.id,
                fullName: values.fullName,
                updatedBy: auth.userDetails,
              };
            }

            if (props.feeType === "student-visa") {
              tag = {
                dateSubmitted: formatDateInKuwait(dateSubmitted),
                fullName: values.fullName,
                phoneNo: phoneNo,
                vfsRefNo: values.vfsRefNo,
                vfsEmail: values.vfsEmail,
                dateOfAppointment: formatDateInKuwait(dateOfAppointment),
                amount: parseInt(values.amount),
                formOfPayment: values.formOfPayment,
                paymentRecieved: values.paymentRecieved,
                incharge: selectedUser.id,
                checkedBy: checkedBy.id,
                vfsUploaded: vfsUploaded,
                updatedBy: auth.userDetails,
              };
            }

            if (props.feeType === "ielts-booking") {
              tag = {
                dateSubmitted: formatDateInKuwait(dateSubmitted),
                fullName: values.fullName,
                passportNo: values.passportNo,
                phoneNo: phoneNo,
                email: values.email,
                formOfTest: values.formOfTest,
                password: values.password,
                typeOfTest: values.typeOfTest,
                refNo: values.refNo,
                dateOfTest: formatDateInKuwait(dateOfTest),
                incharge: selectedUser.id,
                docSent: docSent,
                updatedBy: auth.userDetails,
              };
            }
            if (props.feeType === "english-self-funded") {
              tag = {
                moneyTransferdToSchool: moneyTransferred,
                dateSubmitted: formatDateInKuwait(dateSubmitted),
                arrivalDate: formatDateInKuwait(arrivalDate),
                fullName: values.fullName,
                phoneNo: phoneNo,
                location: values.location,
                school: selectedSchool,
                noOfWeeks: parseInt(values.noOfWeeks),
                amount: parseInt(values.amountPaidKd),
                salesPerson: salesPerson,
                accountManager: accountManager,
                operation: operations,
                updatedBy: auth.userDetails,
              };
            }
            await updateFees(props.selectedFees.id, {
              feeType: props.feeType,
              tag,
              createdDate: props.selectedFees.createdDate,
            });
            props.reloadData(props.feeType);
            setdateSubmitted(new Date());
            values.fullName = "";
            values.password = "";
            values.passportNo = "";
            values.email = "";
            values.refNo = "";
            setphoneNo("");
            values.formOfTest = "";
            values.vfsRefNo = "";
            values.vfsEmail = "";
            setmoneyTransferred(false);
            setdateOfAppointment(new Date());
            values.ofcFee = "";
            values.formOfPayment = "";
            values.paymentRecieved = "";
            setselectedUser("");
            setcheckedBy("");
            setvfsUploaded(false);
            setdateOfTest(new Date());
            setloading(false);
            props.onHide();
          }}
        >
          {({ values }) => (
            <Form>
              <Modal.Header
                style={{
                  position: "sticky",
                  top: 0,
                  background: "#fff",
                  zIndex: 99,
                }}
                closeButton
              >
                <Modal.Title className="modal-title">{`Edit ${props.feeType
                  .split("-")
                  .map(
                    (word: any) => word.charAt(0).toUpperCase() + word.slice(1)
                  )
                  .join(" ")}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="h-100"
                style={{ padding: 15, overflow: "scroll" }}
              >
                {props.feeType === "office-fees" ? (
                  <>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Fee Type</Label>
                      <Select
                        isMulti
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused
                              ? "#7F56D9"
                              : "#d7dfe9",
                            boxShadow: "none",
                          }),
                        }}
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                      />
                    </div>

                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />

                    <FormInput
                      id="amount"
                      name="amount"
                      placeholder=""
                      type="number"
                      label="Amount"
                    />

                    <FormInput
                      id="desc"
                      name="desc"
                      placeholder=""
                      type="text"
                      label="Description"
                    />
                    <div>
                      <Label>Sales Person</Label>
                      <DropdownComponent
                        children={users}
                        onClick={(data: any) => setselectedUser(data)}
                        className=""
                        selected={selectedUser.name}
                        placeholder={""}
                        displayName
                      />
                    </div>
                  </>
                ) : props.feeType === "student-visa" ? (
                  <>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Date Submmited</Label>
                      <ReactDatePicker
                        selected={dateSubmitted}
                        onChange={async (date: any) => {
                          setdateSubmitted(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />
                    <div style={{ marginBottom: 24 }}>
                      <Label>Phone Number</Label>
                      <PhoneInput
                        international
                        defaultCountry="KW"
                        countries={filteredCountries}
                        value={phoneNo}
                        onChange={(e: any) => setphoneNo(e)}
                      />
                    </div>

                    <FormInput
                      id="vfsRefNo"
                      name="vfsRefNo"
                      placeholder=""
                      type="text"
                      label="Vfs Refrence Number"
                    />
                    <FormInput
                      id="vfsEmail"
                      name="vfsEmail"
                      placeholder=""
                      type="text"
                      label="Vfs Email"
                    />

                    <FormInput
                      id="amount"
                      name="amount"
                      placeholder=""
                      type="text"
                      label="Office Fee"
                    />
                    <div style={{ marginBottom: 24 }}>
                      <Label>Date of Appointment</Label>
                      <ReactDatePicker
                        selected={dateOfAppointment}
                        onChange={async (date: any) => {
                          setdateOfAppointment(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                    <div>
                      <Label>Form of Payment</Label>

                      <Field
                        className="form-input"
                        as="select"
                        name="formOfPayment"
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {["Cash", "Payment Link"].map((a) => (
                          <option value={a}>{a}</option>
                        ))}
                      </Field>
                    </div>

                    <div>
                      <Label>Payment Recieved</Label>

                      <Field
                        className="form-input"
                        as="select"
                        name="paymentRecieved"
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {["Yes", "No"].map((a) => (
                          <option value={a}>{a}</option>
                        ))}
                      </Field>
                    </div>
                    <div>
                      <Label>Vfs Uploaded</Label>
                      <br />
                      <ToggleButton
                        style={{ margin: "10px 0" }}
                        label={"vfsUploaded"}
                        onChange={() => {
                          setvfsUploaded(!vfsUploaded);
                        }}
                        checked={vfsUploaded}
                      />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Operation/Visa Officer</Label>
                      <DropdownComponent
                        children={users}
                        onClick={(data: any) => setselectedUser(data)}
                        className=""
                        selected={selectedUser.name}
                        placeholder={""}
                        displayName
                      />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Checked By</Label>
                      <DropdownComponent
                        children={users}
                        onClick={(data: any) => setcheckedBy(data)}
                        className=""
                        selected={checkedBy.name}
                        placeholder={""}
                        displayName
                      />
                    </div>
                  </>
                ) : props.feeType === "english-self-funded" ? (
                  <>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Date Submmited</Label>
                      <ReactDatePicker
                        selected={dateSubmitted}
                        onChange={async (date: any) => {
                          setdateSubmitted(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />
                    <div style={{ marginBottom: 24 }}>
                      <Label>Phone Number</Label>
                      <PhoneInput
                        international
                        defaultCountry="KW"
                        countries={filteredCountries}
                        value={phoneNo}
                        onChange={(e: any) => setphoneNo(e)}
                      />
                    </div>

                    <FormInput
                      id="location"
                      name="location"
                      placeholder=""
                      type="text"
                      label="Location"
                    />
                    <div style={{ marginBottom: 24 }}>
                      <Label>School</Label>
                      <CountryInputComponent
                        selectedCountry={selectedSchool}
                        autoComplete="new-password"
                        setsearchText={setschoolSearch}
                        setselectedCountry={setselectedSchool}
                        removeContry={() => setselectedSchool("")}
                        arabic={false}
                        setaddSchool={setaddSchool}
                        addSchool={addSchool}
                        searchCountry={searchSchool}
                        engSchoolDd
                        countries={schools}
                        setcountries={setschools}
                        searchText={schoolSearch}
                        placeholder={""}
                      />
                    </div>

                    <div style={{ marginBottom: 24 }}>
                      <Label>Arrival Date</Label>
                      <ReactDatePicker
                        selected={arrivalDate}
                        onChange={async (date: any) => {
                          setarrivalDate(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                    <FormInput
                      id="noOfWeeks"
                      name="noOfWeeks"
                      placeholder=""
                      type="text"
                      label="No. of Weeks"
                    />
                    <FormInput
                      id="amountPaidKd"
                      name="amountPaidKd"
                      placeholder=""
                      type="text"
                      label="Amount Paid"
                    />
                    <div>
                      <Label>Money Transferred</Label>
                      <br />
                      <ToggleButton
                        style={{ margin: "10px 0" }}
                        label={"moneyTransferred"}
                        onChange={() => {
                          setmoneyTransferred(!moneyTransferred);
                        }}
                        checked={moneyTransferred}
                      />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Sales</Label>
                      <DropdownComponent
                        children={users}
                        onClick={(data: any) => setsalesPerson(data)}
                        className=""
                        selected={salesPerson.name}
                        placeholder={""}
                        displayName
                      />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Account Manager</Label>
                      <DropdownComponent
                        children={users}
                        onClick={(data: any) => setaccountManager(data)}
                        className=""
                        selected={accountManager.name}
                        placeholder={""}
                        displayName
                      />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Operations</Label>
                      <DropdownComponent
                        children={users}
                        onClick={(data: any) => setoperations(data)}
                        className=""
                        selected={operations.name}
                        placeholder={""}
                        displayName
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Date Submmited</Label>
                      <ReactDatePicker
                        selected={dateSubmitted}
                        onChange={async (date: any) => {
                          setdateSubmitted(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />

                    <FormInput
                      id="passportNo"
                      name="passportNo"
                      placeholder=""
                      type="text"
                      label="Passport Number"
                    />
                    <div style={{ marginBottom: 24 }}>
                      <Label>Phone Number</Label>
                      <PhoneInput
                        international
                        defaultCountry="KW"
                        countries={filteredCountries}
                        value={phoneNo}
                        onChange={(e: any) => setphoneNo(e)}
                      />
                    </div>
                    <FormInput
                      id="email"
                      name="email"
                      placeholder=""
                      type="text"
                      label="email"
                    />

                    <FormInput
                      id="password"
                      name="password"
                      placeholder=""
                      type="password"
                      label="Password"
                    />
                    <FormInput
                      id="refNo"
                      name="refNo"
                      placeholder=""
                      type="text"
                      label="Refrence Number"
                    />
                    <div>
                      <Label>Type of Test</Label>

                      <Field
                        className="form-input"
                        as="select"
                        name="typeOfTest"
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {["IELTS", "UKVI", "OSR"].map((a) => (
                          <option value={a}>{a}</option>
                        ))}
                      </Field>
                    </div>
                    <div>
                      <Label>Form of Test</Label>

                      <Field
                        className="form-input"
                        as="select"
                        name="formOfTest"
                      >
                        <option value="" disabled selected>
                          Select an option
                        </option>
                        {["Paper", "Computer"].map((a) => (
                          <option value={a}>{a}</option>
                        ))}
                      </Field>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Date of Test</Label>
                      <ReactDatePicker
                        selected={dateOfTest}
                        onChange={async (date: any) => {
                          setdateOfTest(date);
                        }}
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <Label>Operation/Visa Officer</Label>
                      <DropdownComponent
                        children={users}
                        onClick={(data: any) => setselectedUser(data)}
                        className=""
                        selected={selectedUser.name}
                        placeholder={""}
                        displayName
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <Label>Documents sent</Label>
                      <ToggleButton
                        label={"docSent"}
                        onChange={() => setdocSent(!docSent)}
                        checked={docSent}
                      />
                    </div>
                  </>
                )}{" "}
                <div className="d-flex" style={{ marginTop: 50 }}>
                  <ButtonComponent
                    label={"Submit"}
                    loading={loading}
                    btnType={"dark"}
                    disabled={loading}
                  />
                  <ButtonComponent
                    label={"Close"}
                    loading={loading}
                    style={{ marginLeft: 20 }}
                    type="button"
                    onClick={() => props.onHide()}
                    btnType={"light"}
                  />
                </div>
                {/* <ButtonComponent btnType="dark" loading={loading} label="Login" /> */}
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
