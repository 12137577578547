import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  createComment,
  deleteComments,
  getUsersWithoutPagination,
  updateComments,
} from "../../Services/Function";
import { Form, Formik, FormikHelpers } from "formik";
import { ButtonComponent } from "../ButtonComponent";
import { useSelector } from "react-redux";
import moment from "moment";
import { MentionsInput, Mention } from "react-mentions";
import Picker from "@emoji-mart/react";
import { DeleteIcon } from "../../Assets";
import { DeleteModal } from "../DeleteModal";

// Define types for User, Comment, Reaction, and Props
type User = {
  id: string;
  name: string;
  slackMemberId: string;
};

type Comment = {
  id: string;
  content: string;
  createdBy: string;
  dateTime: string;
  reactions: any;
  userId: any;
};

type Props = {
  show?: boolean;
  handleClose: () => void;
  comments: Comment[];
  selectedStudent: { id: string };
  getComments: (studentId: string) => void;
};

// Define HighlightedString as it's used in the component
const HighlightedString: React.FC<{ text: string }> = ({ text }) => {
  const highlightedText = text.replace(
    /@(\w+)/g,
    '<span style="color: rgb(127, 86, 217);font-size:14px;background-color: rgb(74 0 237 / 23%);padding: 2px; border-radius: 4px;">@$1</span>'
  );

  return (
    <div
      style={{ fontSize: 14 }}
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  );
};

export const CommentsModal: React.FC<Props> = (props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>("");
  const [commentSeleted, setcommentSeleted] = useState<any>({});
  const [deleteModal, setdeleteModal] = useState(false);
  const [mentions, setMentions] = useState<
    Array<{ id: string; display: string; memberId: string }>
  >([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false); // State to show/hide emoji picker
  const [showEmojiPicker2, setShowEmojiPicker2] = useState<boolean>(false); // State to show/hide emoji picker

  const [selectedCommentId, setSelectedCommentId] = useState<string | null>(
    null
  ); // Track selected comment for reaction
  const [selectedReaction, setSelectedReaction] = useState<string | null>(null); // State to hold selected emoji

  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    async function fetchData() {
      const res = await getUsersWithoutPagination();
      setUsers(res);
    }
    fetchData();
  }, []);

  const usersForMentions = users.map((user) => ({
    id: user.id,
    display: user.name,
    memberId: user.slackMemberId,
  }));

  const handleReaction = async (commentId: string, emoji: string) => {
    await updateComments(commentId, {
      reactions: emoji,
    });
    props.getComments(props.selectedStudent.id);
    // Assuming setComments or similar would be used in a real scenario.
  };

  const addEmoji = (emoji: any) => {
    setCommentText((prevText) => prevText + emoji.native); // Add emoji to comment text
    setShowEmojiPicker(false); // Close the picker after selecting an emoji
  };

  const handleAddReaction = (commentId: string, reaction: any) => {
    if (reaction) {
      handleReaction(commentId, reaction);
      setSelectedReaction(null); // Reset after adding reaction
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      id="modal-right"
      className="modal fade custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "scroll", backgroundColor: "#f7f7f7" }}>
        <ul className="comment-list h-100">
          {props.comments
            .slice()
            .reverse()
            .map((comment: Comment) => (
              <li key={comment.id} className="comment-item">
                <div className="comment-header d-flex justify-content-between">
                  <div className="user-info">
                    <span className="user-name">{comment.createdBy}</span>
                    <span className="comment-date">
                      {moment(comment.dateTime).format("DD/MM/YYYY-hh:mm:a")}
                    </span>
                  </div>
                  {auth.userDetails.id === comment.userId ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setcommentSeleted(comment);
                        setdeleteModal(true);
                      }}
                    >
                      <DeleteIcon width={20} height={20} />
                    </div>
                  ) : null}
                </div>

                <HighlightedString text={comment.content} />

                <div
                  className="reaction-icons"
                  style={{ position: "relative" }}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      bottom: -15,
                      right: 5,
                    }}
                    onClick={() => {
                      setSelectedCommentId(comment.id);
                      setShowEmojiPicker2((prev) => !prev);
                    }}
                  >
                    {comment.reactions ? (
                      comment.reactions
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <rect fill="none" height="24" width="24" />
                        <path d="M18,9V7h-2V2.84C14.77,2.3,13.42,2,11.99,2C6.47,2,2,6.48,2,12s4.47,10,9.99,10C17.52,22,22,17.52,22,12 c0-1.05-0.17-2.05-0.47-3H18z M15.5,8C16.33,8,17,8.67,17,9.5S16.33,11,15.5,11S14,10.33,14,9.5S14.67,8,15.5,8z M8.5,8 C9.33,8,10,8.67,10,9.5S9.33,11,8.5,11S7,10.33,7,9.5S7.67,8,8.5,8z M12,17.5c-2.33,0-4.31-1.46-5.11-3.5h10.22 C16.31,16.04,14.33,17.5,12,17.5z M22,3h2v2h-2v2h-2V5h-2V3h2V1h2V3z" />
                      </svg>
                    )}
                  </span>
                  {showEmojiPicker2 && selectedCommentId === comment.id && (
                    <div style={{ position: "absolute", top: 16, right: 0 }}>
                      <Picker
                        onEmojiSelect={(emoji: any) => {
                          setSelectedCommentId(comment.id);
                          setSelectedReaction(emoji.native);
                          handleAddReaction(
                            comment.id,
                            emoji.native === comment.reactions
                              ? "➕"
                              : emoji.native
                          );
                          setShowEmojiPicker2(false);
                        }}
                      />
                    </div>
                  )}
                </div>

                {/* Add Reaction Emoji */}
              </li>
            ))}
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Formik
          initialValues={{ comment: "" }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            try {
              setLoading(true);

              await createComment({
                studentId: props.selectedStudent.id,
                content: commentText.replace(/@\[([^)]+)\]\([^)]+\)/g, "@$1"),
                dateTime: new Date(),
                createdBy: auth.userDetails.name,
                userId: auth.userDetails.id,
                mentions: mentions,
              });

              props.getComments(props.selectedStudent.id);
              setCommentText("");
              setMentions([]);
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          <Form style={{ width: "70%" }} className="d-flex w-100 flex-column">
            <div className="d-flex align-items-center position-relative">
              <MentionsInput
                id={"commentText"}
                className="textarea-input w-100"
                name={"commentText"}
                forceSuggestionsAboveCursor={true}
                value={commentText}
                onChange={(
                  e: any,
                  _newValue,
                  _newPlainTextValue,
                  mentions: any
                ) => {
                  setCommentText(e.target.value);
                }}
              >
                <Mention
                  trigger="@"
                  data={usersForMentions}
                  appendSpaceOnAdd={true}
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay
                  ) => <div className="user-mention">{highlightedDisplay}</div>}
                  onAdd={(id: string | number, display: string) => {
                    const matchedUser = usersForMentions.find(
                      (user) => user.id === id
                    );

                    if (matchedUser) {
                      setMentions((prevMentions) => [
                        ...prevMentions.filter(
                          (mention) => mention.display !== matchedUser.display
                        ),
                        {
                          display: matchedUser.display,
                          id: matchedUser.id,
                          memberId: matchedUser.memberId,
                        },
                      ]);
                    }
                  }}
                  displayTransform={(_id: any, display: any) => `@${display}`}
                />
              </MentionsInput>
              <div
                className="emoji-picker-icon"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                style={{ marginLeft: "10px", cursor: "pointer", fontSize: 20 }}
              >
                😀
              </div>

              {/* Emoji picker positioned directly below the input */}
              {showEmojiPicker && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "60px",
                    right: "10px",
                  }}
                >
                  <Picker onEmojiSelect={addEmoji} />
                </div>
              )}
            </div>
            <ButtonComponent
              label="Add Comment"
              style={{ margin: "15px 0px 10px 0px" }}
              type="submit"
              disabled={loading}
              loading={loading}
              btnType={"dark"}
            />
          </Form>
        </Formik>
      </Modal.Footer>

      <DeleteModal
        show={deleteModal}
        onHide={() => setdeleteModal(false)}
        onClick={() => deleteComments(commentSeleted.id)}
      />
    </Modal>
  );
};
