import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { updateStudent } from "../../Services/Function";

type Props = {
  onClick: any;
  onHide: any;
  show: boolean;
  selectedUser: any;
  student: any;
  reloadStudents: any;
  id: any;
};

export const AssignRoleModal = (props: Props) => {
  const [roles, setRoles] = useState<string[]>([]);

  const toggleRole = (role: string) => {
    setRoles((prevRoles) =>
      prevRoles.includes(role)
        ? prevRoles.filter((r) => r !== role)
        : [...prevRoles, role]
    );
  };

  const assignRoles = async () => {
    // Step 1: Check if the user already has assigned roles
    const existingAssignment = props.student.assignedTo.find(
      (item: any) => item.user === props.selectedUser.id
    );

    let updatedAssign;

    if (existingAssignment) {
      // Step 2: Merge existing roles with new roles (remove duplicates)
      const updatedRoles = [...new Set([...existingAssignment.role, ...roles])];

      // Step 3: Update the `assignedTo` array with merged roles
      updatedAssign = props.student.assignedTo.map((user: any) =>
        user.user === props.selectedUser.id
          ? { ...user, role: updatedRoles }
          : user
      );
    } else {
      // Step 4: Create a new assignment for the user
      const newAssignments = roles.map((role: any) => ({
        user: props.selectedUser.id,
        role, // Assign each role as a string
      }));

      updatedAssign = [...props.student.assignedTo, ...newAssignments];
    }

    // Step 5: Send a single API call with the updated `assignedTo`
    await updateStudent(props.id, { assignedTo: updatedAssign });

    // Step 6: Reload the students and close the modal
    await props.reloadStudents();
    props.onHide();
  };

  return (
    <div>
      <Modal
        id="myModal"
        show={props.show}
        onHide={() => props.onHide()}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: 18 }}
            id="contained-modal-title-vcenter"
          >
            Assign Roles
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown style={{ marginBottom: 20 }}>
            <Dropdown.Toggle style={{ textTransform: "capitalize" }}>
              {roles.length > 0 ? roles.join(", ") : "Select Roles"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => toggleRole("sales")}
                active={roles.includes("sales")}
                eventKey="1"
              >
                Sales
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => toggleRole("account manager")}
                active={roles.includes("account manager")}
                eventKey="2"
              >
                Account Manager
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => toggleRole("operations")}
                active={roles.includes("operations")}
                eventKey="3"
              >
                Operations
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="d-flex flex-row">
            <ButtonComponent
              btnType="dark"
              loading={false}
              label="Assign"
              onClick={assignRoles}
            />
            <ButtonComponent
              btnType="light"
              loading={false}
              label="Close"
              onClick={() => props.onHide()}
              style={{ marginLeft: 10 }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
